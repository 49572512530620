// external
import {
  Avatar,
  Box,
  Card,
  Chip,
  ChipProps,
  Link,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';

import LocationOn from '@mui/icons-material/LocationOn';
import Star from '@mui/icons-material/Star';
import { LoadingButton } from '@mui/lab';

// internal
import {
  ICustomField,
  IMembership,
  IProfile,
} from '@guider-global/shared-types';
import { LinkedInIcon } from '@guider-global/ui';
import { useMobileMediaQuery } from 'hooks';
import { hexToRGB } from 'utils';

// components
import { MDIcons, SanityIcon } from 'components';

// store
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getFormattedProgramFields,
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { interpolate } from 'functions';
import { useNavigate } from 'react-router-dom';

export interface ProfilePreviewProfileData
  extends Partial<
    Pick<
      IProfile,
      | 'displayName'
      | 'firstName'
      | 'lastName'
      | 'jobTitle'
      | 'townOrCity'
      | 'linkedInUrl'
      | 'organizationFields'
    >
  > {
  pictures: {
    profile?: string;
    user?: string;
  };
}

export interface MembershipDataProps {
  skills: string[];
  membership: IMembership[];
}
interface ProfilePreviewProps {
  programSlug: string | undefined;
  userRole: 'guide' | 'trainee';
  membershipData: MembershipDataProps;
  profileData: ProfilePreviewProfileData;
  showBestMatch?: boolean;
  action?: () => void;
  isLoading?: boolean;
  sxProps?: SxProps<Theme>;
  disabled?: boolean;
}

export const ProfilePreview: React.FC<ProfilePreviewProps> = ({
  disabled = false,
  programSlug,
  profileData,
  membershipData,
  showBestMatch = false,
  userRole,
  action,
  isLoading,
  sxProps = {},
}) => {
  const organizationSlug = getSubDomain();
  const navigate = useNavigate();
  // style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const chipProps: Partial<ChipProps> = {
    size: 'small',
    sx: {
      backgroundColor: `rgba(${hexToRGB(theme.palette.info.main)}, 0.08)`,
      mr: 1,
      mb: 1,
    },
  };

  const { getOrganization } = useSanityOrganization({
    getSilently: false,
    organizationSlug,
  });

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const LinkedInButtonLabel =
    baseLanguage?.programs?.program_registration?.linkedin_profile_button_label;

  const organization = getOrganization();
  const isLinkedInEnabled =
    organization?.personal_details?.linkedin_url?.enabled ?? false;

  // program
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  const skillsConfig = program?.registration?.skills;
  const isSkillsEnabled = skillsConfig?.enable_skills_question;

  const skillSlugs = membershipData?.skills ?? [];

  const membership = membershipData.membership.at(0);

  useEffect(() => {
    if (!program || !membershipData) {
      navigate('/programs');
    }
  }, [membershipData, navigate, program]);

  const { getProfileImage } = useProfileImage();

  if (!program || !membershipData || !membership || !profileData) {
    return <></>;
  }

  const customFields = getFormattedProgramFields(membership, program);

  //program type
  const programType = program.program_details?.program_type;
  const programVariation =
    programType?.program_type_text?.variations?.individual;

  const profileFields = getVisibleProfileFields(
    organization.profile_fields,
    profileData.organizationFields as ICustomField[],
  );

  const image = getProfileImage({
    profilePicture: profileData?.pictures.profile,
    userPicture: profileData?.pictures.user,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        ...sxProps,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: { md: '270px' },
          minWidth: { md: '270px' },
          maxWidth: { xs: '100vw', md: '270px' },
          backgroundColor: `rgba(${hexToRGB(theme.palette.info.main)}, 0.08)`,
          p: 3,
          overflow: 'hidden',
          ...(showBestMatch && { pt: 7 }),
        }}
      >
        {showBestMatch && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: theme.palette.success.main,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 0.5,
              position: 'absolute',
              top: 0,
              zIndex: 1,
            }}
          >
            <Star sx={{ mr: 1, color: 'white', fontSize: '16px' }} />
            <Typography sx={{ color: 'white' }}>
              {baseLanguage?.programs?.program_registration?.best_match_label}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            top: '-60px',
            left: '-15%',
            position: 'absolute',
            width: '130%',
            height: '175px',
            borderRadius: '100%',
            backgroundColor: `rgba(${hexToRGB(theme.palette.info.light)}, 0.5)`,
          }}
        />
        <Card>
          <Avatar
            src={image}
            sx={{ width: '150px', height: '150px', backgroundColor: 'white' }}
            variant="rounded"
          />
        </Card>
        <Typography variant="h5" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
          {profileData?.displayName}
        </Typography>
        {profileData?.jobTitle && (
          <Typography variant="subtitle2" color="text.secondary">
            {profileData?.jobTitle}
          </Typography>
        )}
        {profileData?.townOrCity && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <LocationOn color="info" sx={{ fontSize: 20, mr: 0.5 }} />
            <Typography variant="caption" color="text.secondary">
              {profileData?.townOrCity}
            </Typography>
          </Box>
        )}
        {profileFields?.map((field, index) => {
          if (field?.label) {
            return (
              <Box
                key={`preview-profile-field-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    color: theme.palette.info.main,
                    fontSize: 20,
                    p: 0,
                    mr: 0.5,
                  }}
                >
                  <SanityIcon name={field?.icon?.name as MDIcons} />
                </Box>
                <Typography variant="caption" color="text.secondary">
                  {field.label}
                </Typography>
              </Box>
            );
          }
          return undefined;
        })}
        {isLinkedInEnabled && profileData?.linkedInUrl && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <LinkedInIcon viewBox="0 0 15 15" sx={{ fontSize: 15, mr: 1 }} />
            <Link
              href={
                profileData?.linkedInUrl.includes('http')
                  ? profileData?.linkedInUrl
                  : `http://${profileData?.linkedInUrl}`
              }
              color="info.main"
              underline="none"
              variant="caption"
              sx={{
                ':hover': {
                  color: 'info.dark',
                },
              }}
              target="_blank"
            >
              {LinkedInButtonLabel}
            </Link>
          </Box>
        )}
        {action && (
          <LoadingButton
            data-cy="components_Guide_GuideCard_connect-button"
            variant="contained"
            color="info"
            sx={{ mt: 2 }}
            onClick={action}
            loading={isLoading}
            disabled={disabled}
          >
            {interpolate(
              programVariation?.registration?.registration_trainee
                ?.registration_trainee_choose_a_guide
                ?.registration_trainee_choose_a_guide_choose_guide_button_label ??
                '',
              { name: profileData?.firstName || '' },
              'Connect',
            )}
          </LoadingButton>
        )}
      </Box>
      <Box sx={{ width: 'auto', p: 3 }}>
        {isSkillsEnabled && skillSlugs && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
            }}
          >
            <Typography variant="body1" sx={{ mb: 0.5 }}>
              {skillsConfig?.[userRole].question_heading ??
                skillsConfig?.text?.input_label ??
                ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {skillSlugs?.map((skill, index) => (
                <Chip
                  key={`skills-chip-${skill}-${index}`}
                  label={
                    skillsConfig?.options?.find(
                      (option) => option.id.current === skill,
                    )?.label
                  }
                  {...chipProps}
                />
              ))}
            </Box>
          </Box>
        )}
        {customFields?.map((field, index) => {
          return (
            <Box
              sx={{
                mt: isSkillsEnabled && index === 0 ? 2 : 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'flex-start',
                textAlign: isMobile ? 'center' : 'left',
              }}
              key={`guide-card-question-${index + 1}`}
            >
              <Typography variant="body1">{field.label}</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap={false}
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {field.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
